.label
	font-size: 14px
	line-height: 16px
	color: var(--tg-theme-text-color)
	margin-bottom: 8px

.offer
	font-size: 14px
	line-height: 20px
	color: var(--tg-theme-hint-color)
	text-align: center
	margin-bottom: 95px

	& > span
		color: var(--tg-theme-link-color)

.banks
	position: relative
	min-height: 115px
	overflow-x: scroll
	white-space: nowrap
	-ms-overflow-style: none
	scrollbar-width: none
	margin-bottom: 12px

	&::-webkit-scrollbar
		display: none

	&__wrapper
		display: flex
		position: absolute

	&__button
		position: relative
		display: flex
		flex-direction: column

		padding: 10px
		width: 100px
		min-height: 100px
		overflow: hidden
		margin-right: 12px
		word-break: break-word

		&:before
			content: ''
			position: absolute
			width: 98px
			min-height: 98px
			padding-block: 4px
			top: 0
			left: 0
			border: 1px solid var(--tg-theme-hint-color)
			border-radius: 12px

		&_selected
			&:before
				width: 96px
				min-height: 96px
				border: 2px solid var(--tg-theme-button-color)

	&__icon
		position: absolute
		left: -12px
		top: -12px

		&_wrapper
			position: relative
			width: 36px
			height: 36px
			margin-bottom: 10px

	&__name
		font-size: 12px
		font-weight: 500
		line-height: 16px
		text-align: left
		color: var(--tg-theme-text-color)

	&__userName
		text-align: left
		font-size: 11px
		line-height: 16px
		color: var(--tg-theme-hint-color)
		white-space: pre-wrap
		word-break: break-word

.invalidReceiver
	height: 100vh
	display: flex
	align-items: center
	justify-content: center
	color: var(--tg-theme-text-color)