.loader
	height: 100%
	display: flex
	align-items: center
	justify-content: center


.header
	display: flex
	align-items: center
	padding: 12px 16px

	&__photo
		border-radius: 50%
		overflow: hidden
		background-color: #cecece
		width: 40px
		height: 40px

		img
			width: 100%
			height: 100%

	&__title
		margin-left: 12px
		color: var(--tg-theme-text-color)

	&__arrow
		margin-left: auto

.enter
	display: flex
	flex-direction: column
	margin-top: 34px

	justify-content: center
	text-align: center

	color: var(--tg-theme-text-color)

.dots
	margin-top: 21px
	display: flex
	align-items: center
	justify-content: center

	&__item
		transition: background 100ms ease-in-out
		width: 14px
		height: 14px
		background: var(--tg-theme-hint-color)
		opacity: 0.3
		border-radius: 50%

		&_active
			background: var(--tg-theme-button-color)
			opacity: 1

	&__item + &__item
		margin-left: 22px

.digits__rows
	margin-top: 73px
	justify-content: center
	display: grid
	grid-template-columns: 72px 72px 72px
	grid-auto-rows: 72px
	grid-row-gap: 12px
	grid-column-gap: 20px

.digits__item
	border: 0.75px solid rgba(112, 117, 121, 0.4)
	border-radius: 24px

	-webkit-tap-highlight-color: rgba(0,0,0,0)
	-webkit-tap-highlight-color: transparent
	transition: transform 100ms ease-in-out
	color: var(--tg-theme-text-color)
	font-weight: 300
	font-size: 32px
	line-height: 38px
	text-align: center
	letter-spacing: -0.5px

	&:active
		background-color: transparent
		transform: scale(0.9)

.lostCode
	display: flex
	align-items: center
	justify-content: center
	color: var(--tg-theme-hint-color)
	font-weight: 400
	font-size: 14px
	line-height: 20px
	text-align: center
	letter-spacing: -0.25px

.digits__clear
	-webkit-tap-highlight-color: rgba(0,0,0,0)
	-webkit-tap-highlight-color: transparent
	transition: transform 100ms ease-in-out

	&:active
		background-color: transparent
		transform: scale(0.9)