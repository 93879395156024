.transferTarget
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	margin-bottom: 12px
	margin-top: 64px

	&__photo
		margin-bottom: 8px
		border-radius: 50%
		overflow: hidden
		background-color: #cecece
		width: 64px
		height: 64px

		img
			width: 100%
			height: 100%

	&__text
		color: var(--tg-theme-text-color)
		display: flex
		align-items: center

		& > img
			margin-left: 8px
			margin-right: 4px