.container
	position: relative
	display: flex
	flex-direction: column
	min-height: 100vh
	width: 100%

	&::before
		content: ''
		position: absolute
		width: 100%
		height: 100%
		background-color: var(--tg-theme-bg-color)
		left: 0
		top: 0
		z-index: -1

.header
	display: flex
	align-items: center
	background-color: var(--tg-theme-bg-color)

	padding: 16px 0
	border-radius: 0 0 8px 8px
	margin-bottom: 10px

	& > button
		display: inline-flex
		align-items: center

	&__title
		margin-left: 20px
		color: var(--tg-theme-text-color)
		font-size: 18px
		line-height: 22px

.body
	height: 100%
	width: 100%
	background-color: var(--tg-theme-bg-color)

	padding: 20px 0
	margin-bottom: 32px
	border-radius: 8px 8px 0 0

	&__sum
		display: flex
		align-items: center
		justify-content: center

		& > span:first-child
			font-size: 32px
			line-height: 38px
			margin-right: 4px
			color: var(--tg-theme-text-color)

		& > span:last-child
			font-size: 56px
			line-height: 67px
			color: var(--tg-theme-text-color)

	&__commission
		font-size: 14px
		line-height: 20px
		color: var(--tg-theme-text-color)
		margin-top: 8px
		margin-bottom: 32px
		text-align: center


	&__label
		color: var(--tg-theme-text-color)
		font-size: 14px
		line-height: 16px
		margin-bottom: 8px

.user
	position: relative
	display: flex
	justify-content: space-between
	align-items: center
	padding: 12px 16px

	&:not(:last-child)
		margin-bottom: 16px

	&::before
		content: ''
		position: absolute
		width: 100%
		height: 100%
		left: 0
		top: 0
		border-radius: 12px
		background-color: var(--tg-theme-hint-color)
		opacity: 0.1

	&Data
		display: flex
		flex-direction: column
		color: var(--tg-theme-text-color)

		& > div:first-child
			margin-bottom: 4px

		& > .title
			font-size: 14px
			line-height: 16px

		& > .semibold
			font-size: 16px
			font-weight: 500
			line-height: 20px

		&_withAvatar
			display: flex
			align-items: center

	& > img
		width: 32px
		height: 32px
		border-radius: 9px


	&__avatar
		margin-right: 12px
		width: 40px
		height: 40px
		border-radius: 50%

.footer
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	margin-bottom: 100px

	&__info
		display: flex
		align-items: center
		justify-content: center

		& > span
			color: var(--tg-theme-hint-color)
			font-size: 14px
			line-height: 20px

		& > img:first-child
			width: 24px
			height: 24px
			margin-right: 6px

		& > img:last-child
			width: 18px
			height: 18px
			margin-left: 6px

	&_questions
		font-size: 14px
		line-height: 20px
		color: var(--tg-theme-hint-color)

		& > span
			color: var(--tg-theme-link-color)

	&_success
		display: flex
		align-items: center
		justify-content: center

		margin-bottom: 32px

		&__icon
			background-color: var(--tg-theme-link-color)
			display: flex
			align-items: center
			justify-content: center
			border-radius: 50%
			width: 14px
			height: 14px

		&__text
			font-size: 16px
			line-height: 20px
			color: var(--tg-theme-text-color)
			margin-left: 6px

		&__date
			font-size: 16px
			line-height: 20px
			color: var(--tg-theme-hint-color)
			margin-left: 6px

.projectLogo
	width: 32px
	height: 34px