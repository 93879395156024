.sum
	display: flex
	align-items: center
	justify-content: center

	& > span:first-child
		font-size: 32px
		line-height: 38px
		margin-right: 4px
		color: var(--tg-theme-text-color)

	& > span:last-child
		font-size: 56px
		line-height: 67px
		color: var(--tg-theme-text-color)

.suffix
	font-size: 14px
	line-height: 20px
	color: var(--tg-theme-text-color)
	margin-top: 8px
	margin-bottom: 32px
	text-align: center
	display: flex
	justify-content: center

	& > div
		max-width: 260px