.container
	background: #f7f7f9
	margin: 0
	color: #2F3441
	font-size: 13px
	position: relative
	padding-bottom: 149px
	min-height: 100%
	min-width: 320px

.header
	display: flex
	align-items: center
	padding: 16px
	border-radius: 0px 0px 8px 8px
	background: #FFFFFF

	& > button
		width: 24px
		height: 24px
		margin-right: 20px

	& > img
		width: 32px
		height: 32px
		margin-right: 12px

	&__title
		font-weight: 500
		font-size: 18px
		line-height: 22px

.bank_header
	border-bottom: 1px solid #dce1e7
	height: 50px
	background: white
	padding: 0 20px
	line-height: 50px

.vtbLogo
	width: 80px
	margin-top: 10px

.secureLogo
	float: right
	width: 70px
	margin-top: 14px

.center
	text-align: center

.title
	font-size: 16px
	margin: 8px 0 3px

.amount
	font-size: 32px
	line-height: 48px
	font-weight: 300

.enterCode
	font-size: 15px
	font-weight: 700
	margin-top: 8px
	line-height: 20px

.number
	line-height: 20px

.code
	width: 190px
	border: 1px solid #aaa
	border-radius: 3px
	height: 48px
	margin: 14px 0 0
	font-size: 22px
	text-align: center
	padding: 0 15px
	color: #2f3441

.padding
	padding: 0 24px
	font-size: 13px

.codeExpire
	margin: 19px 0 17px

.timer
	color: #6b7683
	display: flex
	justify-content: space-between

.timeExpire
	display: inline

.resend
	float: left
	width: 42%
	text-align: right
	color: #2d55d2
	cursor: pointer

table
	display: table
	border-collapse: separate
	box-sizing: border-box
	text-indent: initial
	border-spacing: 2px
	border-color: grey

tbody
	display: table-row-group
	vertical-align: middle
	border-color: inherit

tr
	display: table-row
	vertical-align: inherit
	border-color: inherit

.transInfo
	font-size: 13px
	border-top: 2px solid #dce1e7
	width: 100%
	padding: 14px 0 26px

	& .row
		padding-left: 20px
		padding-top: 4px
		padding-bottom: 4px
		display: flex

		& > div:first-child
			width: 145px

		& > div:last-child
			width: 200px

.send
	background: #2d55d2
	color: white
	border: 0
	width: 100%
	height: 54px
	font-size: 15px
	transition: 0.2s
	appearance: none
	-webkit-appearance: none
	display: flex
	justify-content: center
	align-items: center


	&:disabled
		background: #999999

	img
		width: 24px
		position: relative
		left: 19px
		top: 2px

.btnCancel
	background: none
	color: #2d55d2
	border: 0
	display: inline-block
	font-size: 15px
	padding: 0
	margin: 22px auto


.instruction
	font-size: 13px
	color: #828899
	line-height: 20px
	padding: 0 19px 30px

.footer
	font-size: 13px
	background: white
	border-top: 2px solid #dce1e7
	padding: 25px 19px 29px
	color: #828899
	line-height: 20px
	position: absolute
	left: 0
	bottom: 0
	width: 100%

	& .phone
		font-size: 15px
		margin-top: 13px
		color: #2f3441