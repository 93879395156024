.container
	min-height: 100vh
	background-color: var(--tg-theme-bg-color)

.header
	padding: 16px
	display: flex
	align-items: center
	justify-content: flex-start

	& > button
		display: inline-flex
		align-items: center

	&__title
		padding-left: 20px
		color: var(--tg-theme-text-color)
		font-weight: 500
		font-size: 18px

.body
	padding: 20px 16px

	&__label
		font-size: 14px
		line-height: 16px
		margin-bottom: 8px
		color: var(--tg-theme-text-color)

	&__description
		color: var(--tg-theme-hint-color)
		font-size: 14px
		line-height: 20px

	&_header
		display: flex
		justify-content: space-between
		align-items: center

		margin-bottom: 20px

		&__title
			color: var(--tg-theme-text-color)
			font-size: 18px
			line-height: 22px

		&__link
			color: var(--tg-theme-link-color)
			font-size: 14px
			line-height: 20px


.vtbInput
	border-radius: 12px
	background-color: #0A2896

	\:global
		.tk-form-field__flex
			transition: background-color .2s
			background-color: #0A2896

		.tk-input
			color: #fff !important

		.tk-label
			color: #fff !important

.inputLogo
	width: 32px
	height: 32px

.inputBlock
	position: relative
	padding: 12px 16px
	margin-bottom: 20px
	color: var(--tg-theme-text-color)

	display: flex
	align-items: center
	justify-content: space-between

	&__refillSum
		font-size: 16px
		line-height: 20px
		font-weight: 500

	&__sum
		display: flex
		flex-direction: column

		& > span:first-child
			font-size: 14px
			line-height: 16px
			margin-bottom: 4px

		& > span:last-child
			font-size: 16px
			line-height: 20px
			font-weight: 500


	&:before
		content: ''
		position: absolute
		left: 0
		top: 0
		width: 100%
		height: 100%
		border-radius: 12px
		border: 1px solid var(--tg-theme-hint-color)
		opacity: 0.3

	&__suffix
		display: flex
		align-items: center
		justify-content: center

		& > span
			color: var(--tg-theme-hint-color)
			font-size: 16px
			line-height: 20px

		& > div
			margin-left: 12px
			display: flex
			align-items: center
			justify-content: center
			width: 32px
			height: 32px
			background-color: #FFFFFF
			border-radius: 6px

.projectLogo
	width: 32px
	height: 34px