.button
	padding: 7px 16px
	font-size: 14px
	line-height: 18px
	border-radius: 16px
	transition: background-color .2s

\:global
	.button__primary
		background-color: var(--tg-theme-button-color)
		color: var(--tg-theme-button-text-color)

	.button__muted
		color: var(--tg-theme-hint-color)
