.tk-select
	&__arrow
		transition: transform 0.12s

	&--opened
		&__arrow
			transform: rotate(180deg)

	&__panel
		padding: 12px 0

	&__suffix-wrapper
		& > *:not(:last-child)
			margin-right: 8px

.tk-form-field.tk-form-field--has-label .tk-select__suffix-wrapper
	margin-top: -9px // Когда select внутри form-field его стрелочка должна быть посередине филда, а не селекта

.tk-form-field:not(.tk-form-field--has-label) .tk-select-trigger
	padding: 18px 16px