@font-face
	font-family: Inter
	src: url('../assets/fonts/Inter-Light.ttf') format('ttf')
	font-weight: 300
	font-style: normal

@font-face
	font-family: Inter
	src: url('../assets/fonts/Inter-Regular.ttf') format('ttf')
	font-weight: normal
	font-style: normal

@font-face
	font-family: Inter
	src: url('../assets/fonts/Inter-Medium.ttf') format('ttf')
	font-weight: 500
	font-style: normal

@font-face
	font-family: Inter
	src: url('../assets/fonts/Inter-SemiBold.ttf') format('ttf')
	font-weight: 600
	font-style: normal

@font-face
	font-family: Inter
	src: url('../assets/fonts/Inter-Bold.ttf') format('ttf')
	font-weight: bold
	font-style: normal