.container
	display: flex
	flex-direction: column
	align-items: center
	background-color: var(--tg-theme-bg-color)
	height: 100%


.transferTarget
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	margin-bottom: 12px
	margin-top: 64px

	&__photo
		margin-bottom: 8px
		border-radius: 50%
		background-color: #cecece
		width: 64px
		height: 64px

	&__text
		color: var(--tg-theme-text-color)
		display: flex
		align-items: center

		& > img
			margin-left: 8px
			margin-right: 4px

	&__request
		text-align: center
		color: var(--tg-theme-link-color)
		font-size: 16px
		line-height: 20px

		margin-bottom: 50px

.currency
	display: flex
	align-items: center
	margin-bottom: 120px
	justify-content: center

	& button
		&:not(:last-child)
			margin-right: 8px

	&__button
		padding: 7px 16px
		font-size: 14px
		line-height: 18px
		border-radius: 16px
		background-color: rgba(var(--tg-theme-link-color), 0.06)
		color: var(--tg-theme-hint-color)

		&_black
			color: var(tg-theme-text-color)

		&_active
			background-color: var(--tg-theme-button-color)
			color: var(--tg-theme-button-text-color)



.input
	position: relative
	display: flex
	align-items: center
	justify-content: center
	padding: 0 16px
	width: 100%
	margin-top: 50px

	&__mask
		position: absolute
		visibility: hidden
		font-size: 56px
		line-height: 66px
		max-width: 380px

	&__currency
		position: absolute
		z-index: 1

	\:global
		.tk-form-field
			margin-bottom: 20px
			&:before
				background-color: var(--tg-theme-bg-color)

		.tk-input
			padding: 0

		.tk-form-field__prefix
			position: absolute
			width: 100%
			height: 100%
			padding-left: 0
			font-size: 32px
			line-height: 38px

	& input
		font-size: 56px
		line-height: 66px
		color: var(--tg-theme-text-color)
		text-align: center

		&::placeholder
			color: var(--tg-theme-text-color)

		&:focus::placeholder
			color: transparent



	&__commission
		position: absolute
		color: var(--tg-theme-hint-color)
		font-size: 14px
		line-height: 20px
		bottom: 0


.balance
	position: relative
	padding: 12px 16px
	display: flex
	align-items: center
	margin-bottom: 64px


	&:before
		content: ''
		position: absolute
		left: 0
		top: 0
		width: 100%
		height: 100%
		border: 1px solid var(--tg-theme-hint-color)
		border-radius: 12px
		opacity: 0.1

	& > img
		width: 32px
		height: 32px
		margin-right: 12px

	& > button
		position: relative
		padding: 10px 24px
		font-size: 14px
		line-height: 18px
		color: var(--tg-theme-link-color)

		&:before
			content: ''
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			border: 1px solid var(--tg-theme-hint-color)
			border-radius: 12px
			opacity: 0.1

	&__data
		display: flex
		flex-direction: column
		color: var(--tg-theme-text-color)
		margin-right: 20px

		& > span:first-child
			font-size: 14px
			line-height: 16px
			margin-bottom: 4px

		& > span:last-child
			font-size: 16px
			line-height: 20px
			font-weight: 500


.projectLogo
	width: 32px
	height: 34px