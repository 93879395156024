.container
	height: 100vh
	background-color: var(--tg-theme-bg-color)
	padding: 12px 16px

.header
	display: flex
	align-items: center

	color: var(--tg-theme-text-color)

	margin-bottom: 20px

	& > img
		width: 40px
		height: 40px
		border-radius: 50%
		background-color: #cecece
		margin-right: 12px

.body

	&_header
		display: flex
		align-items: center
		justify-content: space-between
		margin-bottom: 16px

		font-size: 16px
		line-height: 20px

		& > span:first-child
			color: var(--tg-theme-text-color)

		& > span:last-child
			color: var(--tg-theme-link-color)

	&_sum
		display: flex
		align-items: center
		justify-content: center
		margin-bottom: 16px

		&__currency
			font-size: 32px
			line-height: 38px
			margin-right: 4px
			color: var(--tg-theme-text-color)

		&__sum
			font-size: 48px
			line-height: 57px
			color: var(--tg-theme-text-color)

	&_buttons
		display: flex
		justify-content: center
		align-items: center

		margin-bottom: 36px

		& > button:not(:last-child)
			margin-right: 6px

	&_refill
		display: flex
		align-items: center
		justify-content: center
		margin-bottom: 90px

		&__icon
			margin-right: 8px

		& > button
			display: flex
			align-items: center
			justify-content: center

			padding: 12px 32px
			border-radius: 22px

			color: var(--tg-theme-button-text-color)
			background-color: var(--tg-theme-button-color)

			& > span
				font-size: 14px
				line-height: 18px

	&_history
		padding-bottom: 12px

		&__header
			display: flex
			justify-content: space-between
			align-items: center
			margin-bottom: 20px

			.title
				font-weight: 500
				font-size: 18px
				line-height: 22px
				color: var(--tg-theme-text-color)

.history__item
	display: flex
	align-items: center
	padding: 12px 16px

	border: 1px solid rgba(112, 117, 121, 0.2)
	border-radius: 12px

	&__photo
		margin-left: auto

		width: 32px
		height: 32px
		overflow: hidden
		border-radius: 9px

		img
			width: 100%
			height: 100%

	&__receiver
		font-weight: 400
		font-size: 16px
		line-height: 20px
		letter-spacing: -0.4px

		color: var(--tg-theme-text-color)

	&__sum
		margin-top: 4px
		font-weight: 500
		font-size: 16px
		line-height: 20px
		letter-spacing: -0.25px

		color: var(--tg-theme-text-color)
		opacity: 0.8

.history__item + .history__item
	margin-top: 16px
