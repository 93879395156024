body
	font-family: Inter, sans-serif !important
	font-style: normal
	font-weight: normal
	font-size: 18px
	line-height: 28px
	font-variant-ligatures: none
	text-rendering: optimizeLegibility
	font-smoothing: antialiased
	font-smooth: always
	-webkit-text-size-adjust: 100%
	text-shadow: 0 0 1px rgba(black, 0.01)
	width: 100vw
	overflow-x: hidden !important

html, body, #root
	height: var(--tg-viewport-height)

body
	background-color: var(--tg-theme-bg-color)
