@import "mixins"

.tk-calendar
	width: 408px

	&__header
		padding: 0 4px
		height: 72px
		border-bottom: 1px solid rgba($c-black, 0.08)
		color: rgba($c-black, 0.64)

		button
			margin: 0 12px

	&__month
		font-weight: 500
		font-size: 16px
		line-height: 24px
		color: $c-black

	&__year
		font-size: 14px
		line-height: 20px
		color: rgba($c-black, 0.64)

	&__weekdays
		background: $c-white
		font-size: 10px
		line-height: 16px
		padding: 0 8px
		height: 32px
		text-transform: uppercase

	&__weekday
		width: 56px

	&__days
		padding: 8px

	&__day
		width: 32px
		height: 32px
		padding: 7px 4px
		font-size: 14px
		line-height: 18px
		color: $c-black
		border-radius: 8px
		margin: 12px

		&:hover:not(.tk-calendar__day--active):not(.tk-calendar__day--selected)
			background: $c-white

		&--weekend
			color: $c-error

		&--today
			color: $c-primary-100
			font-weight: 500

		&--working
			color: $c-black

		&--other
			opacity: 0.32

		&--selected
			background: rgba($c-base-72, 0.08)
			border-radius: 0
			position: relative

			&:after
				display: block
				content: ""
				width: 24px
				height: 32px
				right: -24px
				position: absolute
				background: rgba($c-base-72, 0.08)

		&--monday
			border-radius: 8px 0 0 8px

		&--sunday
			border-radius: 0 8px 8px 0

			&:after
				content: none

		&--selection-end
			&:after
				content: none

		&--active, &--selection-start, &--selection-end
			position: relative
			background: $c-primary-80
			color: white
			font-weight: 400
			border-radius: 8px

			&:before
				content: ""
				width: 32px
				height: 32px
				background: rgba($c-base-72, 0.08)
				position: absolute
				z-index: -1

		&--selection-start
			&:before
				border-radius: 8px 0 0 8px

		&--selection-end
			&:before
				border-radius: 0 8px 8px 0
