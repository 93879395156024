.ring
	display: inline-block
	position: relative
	width: 80px
	height: 80px

.ring div
	box-sizing: border-box
	display: block
	position: absolute
	width: 64px
	height: 64px
	margin: 8px
	border: 8px solid var(--tg-theme-link-color)
	border-radius: 50%
	animation: ring-spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
	border-color: var(--tg-theme-link-color) transparent transparent transparent

.ring div:nth-child(1)
	animation-delay: -0.45s

.ring div:nth-child(2)
	animation-delay: -0.3s

.ring div:nth-child(3)
	animation-delay: -0.15s

@keyframes ring-spin
	0%
		transform: rotate(0deg)

	100%
		transform: rotate(360deg)