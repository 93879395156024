$base-color: #ddd
$shine-color: #e8e8e8
$animation-duration: 1s

.skeleton
	background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px)
	background-size: 400px
	border-radius: 12px
	animation: shine-lines $animation-duration infinite linear

@keyframes shine-lines
	0%
		background-position: -100px

	100%
		background-position: 140px