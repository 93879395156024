.wrapper
	height: 100%
	background-color: var(--tg-theme-bg-color)
	color: var(--tg-theme-text-color)
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center

.container
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	width: 340px
	text-align: center

.logo
	margin-bottom: 32px

.title
	font-weight: 600
	font-size: 24px
	line-height: 28px
	margin-bottom: 16px

.description
	font-size: 14px
	line-height: 18px
